<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Fields
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
    </div>
    <form class="form">
      <div class="card-body">
		<div id="app">
		<div class="repeater" v-if="repeaterData">
				<table class="table table-responsive">
					<thead>
					<tr>
					  <th></th>
					  <th>Field</th>
					  <th>Hid.</th>
					  <th>Dis.</th>
					  <th>Req.</th>
					  <th width="90px">Cols</th>
					  <th>Validation</th>
					  <th width="150px">Label</th>
            <th width="150px">Watch</th>
					  <th>Type</th>
					  <th></th>
					</tr>
				  </thead>
					<tbody>

						<tr :value="repeaterData" v-for="(col, index) in repeaterData" :key="index">
							<td><span class="input-group-btn-vertical">
							<a style="cursor: pointer;" v-on:click="positionUp(index)" class="link">Up</a><br/>
							<a style="cursor: pointer;" v-on:click="positionDown(index)" class="">Down</a>
							</span>
							</td>
							<td>
							<el-select v-on:change="update_checkbox(col.field, index)" v-model="col.field" :placeholder="col.field">
								<el-option
								  v-for="item in options_col_field"
								  :key="item.value"
								  :label="item.text"
								  :value="item.value">
								</el-option>
							  </el-select>
							</td>
							
							<td>
							<el-checkbox v-if="col.field" v-on:change="checkbox_hidden_set_value(index, $event)" v-model="field_hidden[index]" true-value="true" false-value="false" ></el-checkbox>
							</td>
							
							<td>
							<el-checkbox v-if="col.field" v-on:change="checkbox_disabled_set_value(index, $event)" v-model="field_disabled[index]" true-value="true" false-value="false" ></el-checkbox>
							</td>
							
							<td>
							<el-checkbox v-if="col.field" @change="checkbox_required_set_value(index, $event)" v-model="field_required[index]" true-value="true" false-value="false" :disabled="check_disabled(col.field, index)"></el-checkbox>
							</td>
							
							<td>
							<el-select v-model="col.cols" :placeholder="col.cols">
									<el-option
									  v-for="item in options_col_numbers"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							</td>
							<td>
							  <el-select v-model="col.validation" :placeholder="col.validation">
									<el-option
									  v-for="item in options_col_validation"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							  
							  </td>
							<td><input class="form-control form-control-solid" type="text" v-model="col.label"></td>
              <td><input class="form-control form-control-solid" type="text" v-model="col.watch"></td>
							<td>
							  <el-select v-model="col.type" :placeholder="col.type">
									<el-option
									  v-for="item in options_col_type"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
							</td>
							<td>
							<div class="modal fade" tabindex="-1" :id="'modalFieldsOptions_'+index">
							  <div class="modal-dialog modal-lg">
								<div class="modal-content">
								  <div class="modal-header">
									<h5 class="modal-title">Extra options for {{col.field}}</h5>

									<!--begin::Close-->
									<div
									  class="btn btn-icon btn-sm btn-active-light-primary ms-2"
									  data-bs-dismiss="modal"
									  aria-label="Close"
									>
									  <span class="svg-icon svg-icon-2x"></span>
									</div>
									<!--end::Close-->
								  </div>
								  <div v-if="col.options" class="modal-body">

                    <div v-if="col.type != ''">
                      defaultValue:
                     <input :ref="'option_field_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'defaultValue')" :value="checkOptions(col, 'defaultValue')">
                    defaultValue Source:
                    <input :ref="'option_field_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'defaultValueSource')" :value="checkOptions(col, 'defaultValueSource')">
                    </div>
								  
								  <div v-if="col.type == 'defaultRouteField'">
								    <template v-if="col.type == 'defaultRouteField'">Route Field Name</template>
									<input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'default_route_field')" :value="checkOptions(col, 'default_route_field')">
									<br/>
									</div>

                  <div v-if="col.type == 'number'">
								  Minimum Number (Quantity or FieldName)
									<input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'minimumNumber')" :value="checkOptions(col, 'minimumNumber')">
                  <br/>
                  Maximum Number (Quantity or FieldName)
                  <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'maximumNumber')" :value="checkOptions(col, 'maximumNumber')">
									</div>
								 
								 <div v-if="col.type == 'simpleLink' || col.type == 'address' || col.type == 'status' || col.type == 'countryFlag' || col.type == 'select2' || col.type == 'uploadImageSingle' || col.type == 'selectSearch' || col.type == 'selectMultiSearch' || col.type == 'selectSearchProduct'">
									Selection
									<br/>
								   <el-select style="width:100%" v-if="col.options" v-model="col.options['selection']" :placeholder="col.options['selection']">
									<el-option
									  v-for="item in options_selection"
									  :key="item.value"
									  :label="item.text"
									  :value="item.value">
									</el-option>
								  </el-select>
								  <br/>
								  <input :ref="'option_selection_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'selection')" type="hidden" :value="checkOptions(col, 'selection')">
									<br/>
									</div>
									

								    <div v-if="col.options['selection']">


								    <template v-if="col.options['selection'] == 'route'">API Endpoint
                      <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'link')" :value="checkOptions(col, 'link')">
                    </template>
                    
                    <template v-if="col.options['selection'] == 'settings'">Key
                      <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'link')" :value="checkOptions(col, 'link')">
                    </template>
						
									<br/>
            
									</div>

                  <div v-if="col.options['selection'] === 'external'">
                        Field options endpoint:
                        <input :ref="'option_link_'+index" class="form-control form-control-solid" type="text" @change="change_option(index, $event.target.value, 'link')" :value="checkOptions(col, 'link')">
                        
                        IndexName
                        <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'indexName')" type="text" :value="checkOptions(col, 'indexName')">

                        IndexType
                        <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'indexType')" type="text" :value="checkOptions(col, 'indexType')">

                        DispatchMethod
                        <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'dispatchMethod')" type="text" :value="checkOptions(col, 'dispatchMethod')">
                      
                        Label Field (which field should me the label)
                        <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'labelField')" type="text" :value="checkOptions(col, 'labelField')">
                      										Filter
										<input @change="change_option(index, $event.target.value, 'route_filter')" :ref="'option_field_'+index" class="form-control form-control-solid" type="hidden" :value="JSON.stringify(routeFilter[index])">
		
										<div class="repeater" v-if="'routeFilter_'+index">
											<table class="table table-responsive">
												<thead>
												<tr>
												  <th></th>
												  <th>Type</th>
												  <th>Field</th>
												  <th>Operator</th>
													<th>Method</th>
												  <th>Value</th>
												  <th></th>
												</tr>
											  </thead>
												<tbody>
													
													<tr :value="'routeFilter_'+index" v-for="(col, index_filter) in routeFilter[index]" :key="index_filter">
														<td></td>
														<td>
														  <el-select v-on:change="repeaterFilterUpdate" v-model="col.type_filterRoute" :placeholder="col.type_filterRoute">
															<el-option
															  v-for="item in options_col_type_filterRoute"
															  :key="item.value"
															  :label="item.text"
															  :value="item.value">
															</el-option>
														  </el-select>
														</td>
														
														<td><el-select v-on:click="repeaterFilterGetFieldOptions(index)" v-on:change="repeaterFilterUpdate" v-model="col.field_filterRoute" :placeholder="col.field_filterRoute">
																	<el-option
																	  v-for="item in options_col_field_filter[index]"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
														
														<td><el-select v-on:change="repeaterFilterUpdate" v-model="col.operator_filterRoute" :placeholder="col.operator_filterRoute">
																	<el-option
																	  v-for="item in options_col_operator"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
																
																<td><el-select v-on:change="repeaterFilterUpdate" v-model="col.operator_filterMethod" :placeholder="col.operator_filterMethod">
																	<el-option
																	  v-for="item in options_col_method"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
														
														<td><input v-if="col.type_filterRoute == 'id'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															<input v-if="col.type_filterRoute == 'customValue'" class="form-control form-control-solid" type="text" v-model="col.value">
															<input v-if="col.type_filterRoute == 'parentValue'" class="form-control form-control-solid" type="text" v-model="col.parentValue">
															<input v-if="col.type_filterRoute == 'parentRenderId'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
                                <input v-if="col.type_filterRoute == 'routeData'" class="form-control form-control-solid" type="text" v-model="col.value">
															<input v-if="col.type_filterRoute == 'currentForm'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															<input v-if="col.type_filterRoute == 'select2SearchInput'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															</td>
														<td></td>
														
														
														<td><a class="btn btn-danger btn-sm" v-on:click="removeRouteFilter($event, index_filter, index)"><i class="fa fa-trash"></i></a></td>
													</tr>
													<tr><td colspan="6"></td><td><button
														  type="button"
														  class="btn btn-sm btn-secondary"
														  v-on:click="generateRouteFilter(index)"
														>
														 <i class="fa fa-plus"></i>
														</button></td></tr>
												</tbody>
											</table>
											
										</div>
                      </div>

                  
                  <!-- Addon v2.0 -->
                  <div v-if="col.options['selection'] == 'settings'">

                    fieldName (to Watch) (Render v3)
                    <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'fieldName')" type="text" :value="checkOptions(col, 'fieldName')">

                    configSource (Render v3)
                    <input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'configSource')" type="text" :value="checkOptions(col, 'configSource')">

                  </div>

									<div v-if="col.options['selection'] == 'route'">
									Field
									<input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'field')" type="text" :value="checkOptions(col, 'field')">
									<br/></div>
									
									<div v-if="col.options['selection'] == 'route'">
									Veux - function (endpoint)
									<input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'endpoint')" type="text" :value="checkOptions(col, 'endpoint')">
									</div>
									
									<div v-if="col.options['selection'] == 'route'">
									Veux - function name (getAll)
									<input :ref="'option_field_'+index" class="form-control form-control-solid" @change="change_option(index, $event.target.value, 'getAll')" type="text" :value="checkOptions(col, 'getAll')">
									</div>
									
									<br/>
									
									<div v-if="col.options['selection']">
										<div v-if="col.options['selection'] == 'route'">
										Filter
										<input @change="change_option(index, $event.target.value, 'route_filter')" :ref="'option_field_'+index" class="form-control form-control-solid" type="hidden" :value="JSON.stringify(routeFilter[index])">
		
										<div class="repeater" v-if="'routeFilter_'+index">
											<table class="table table-responsive">
												<thead>
												<tr>
												  <th></th>
												  <th>Type</th>
												  <th>Field</th>
												  <th>Operator</th>
													<th>Method</th>
												  <th>Value</th>
												  <th></th>
												</tr>
											  </thead>
												<tbody>
													
													<tr :value="'routeFilter_'+index" v-for="(col, index_filter) in routeFilter[index]" :key="index_filter">
														<td></td>
														<td>
														  <el-select v-on:change="repeaterFilterUpdate" v-model="col.type_filterRoute" :placeholder="col.type_filterRoute">
															<el-option
															  v-for="item in options_col_type_filterRoute"
															  :key="item.value"
															  :label="item.text"
															  :value="item.value">
															</el-option>
														  </el-select>
														</td>
														
														<td><el-select v-on:click="repeaterFilterGetFieldOptions(index)" v-on:change="repeaterFilterUpdate" v-model="col.field_filterRoute" :placeholder="col.field_filterRoute">
																	<el-option
																	  v-for="item in options_col_field_filter[index]"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
														
														<td><el-select v-on:change="repeaterFilterUpdate" v-model="col.operator_filterRoute" :placeholder="col.operator_filterRoute">
																	<el-option
																	  v-for="item in options_col_operator"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
																
																<td><el-select v-on:change="repeaterFilterUpdate" v-model="col.operator_filterMethod" :placeholder="col.operator_filterMethod">
																	<el-option
																	  v-for="item in options_col_method"
																	  :key="item.value"
																	  :label="item.text"
																	  :value="item.value">
																	</el-option>
																  </el-select></td>
														
														<td><input v-if="col.type_filterRoute == 'id'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															<input v-if="col.type_filterRoute == 'customValue'" class="form-control form-control-solid" type="text" v-model="col.value">
															<input v-if="col.type_filterRoute == 'parentValue'" class="form-control form-control-solid" type="text" v-model="col.parentValue">
															<input v-if="col.type_filterRoute == 'parentRenderId'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
                                <input v-if="col.type_filterRoute == 'routeData'" class="form-control form-control-solid" type="text" v-model="col.value">
															<input v-if="col.type_filterRoute == 'currentForm'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															<input v-if="col.type_filterRoute == 'select2SearchInput'" class="form-control form-control-solid" disabled="disabled" type="text" value="<disabled>">
															</td>
														<td></td>
														
														
														<td><a class="btn btn-danger btn-sm" v-on:click="removeRouteFilter($event, index_filter, index)"><i class="fa fa-trash"></i></a></td>
													</tr>
													<tr><td colspan="6"></td><td><button
														  type="button"
														  class="btn btn-sm btn-secondary"
														  v-on:click="generateRouteFilter(index)"
														>
														 <i class="fa fa-plus"></i>
														</button></td></tr>
												</tbody>
											</table>
											
										</div>
									</div>

									</div>
									
								  </div>

								  <div class="modal-footer">
									<button
									  type="button"
									  class="btn btn-light"
									  data-bs-dismiss="modal"
									>
									  Close
									</button>
									<button v-on:click="options_continue(index)" type="button" class="btn btn-primary" data-bs-dismiss="modal">
										Continue
									</button>
								  </div>
								</div>
							  </div>
							</div>
							
							<button
							  v-on:click="select_option(index, '', 'selection')"
							  v-if="col.type != '' || col.type == 'simpleLink' || col.type == 'address' || col.type == 'status' || col.type == 'countryFlag' || col.type == 'select2' || col.type == 'defaultRouteField' || col.type== 'number'  || col.type== 'selectSearch' || col.type == 'selectMultiSearch' || col.type == 'selectSearchProduct' || col.type == 'uploadImageSingle'"
							  type="button"
							  class="btn btn-primary btn-sm"
							  data-bs-toggle="modal"
							  :data-bs-target="'#modalFieldsOptions_'+index"
							>
							 <i class="fa fa-cog"></i>
							</button>
					
							<input class="form-control form-control-solid" type="hidden" :value="JSON.stringify(col.options)" ></td>
							
							
							<td><a class="btn btn-danger btn-sm" v-on:click="remove($event, index)"><i class="fa fa-trash"></i></a></td>
						</tr>
						<tr><td colspan="8"></td><td><button
							  type="button"
							  class="btn btn-sm btn-secondary"
							  v-on:click="generate"
							>
							 <i class="fa fa-plus"></i>
							</button></td></tr>
					</tbody>
				</table>
				
			</div>
              <input 
                class="form-control form-control-lg form-control-solid"
                ref="Fields"
                type="hidden"
				:value="JSON.stringify(repeaterData)"
              />
      </div>

      </div>
	  <div class="card-footer d-flex justify-content-end py-6 px-9">
	  <span :class="spinner"></span>
      <button
        type="button"
        class="btn btn-primary"
        @click="saveTrigger()"
        ref="kt_save_changes"
      >
      Save Changes
      </button>
    </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

   export default {
      data(){
          return {
		  field_required: [],
		  field_disabled: [],
		  field_hidden: [],
		  routeFilter: [[]],
		  repeaterData: null,
		  options_col_operator: [
			  { value: null, text: '' },
			  { value: '=', text: 'Equal' },
			  { value: '!=', text: 'Not equal' },
			  { value: 'like', text: 'Contains' },
			  { value: 'not_like', text: 'Does not contain' },
        { value: 'IS NULL', text: 'Is Null' }
			],
		  options_col_type_filterRoute: [
			  { value: null, text: '' },
			  { value: 'id', text: 'Route ID' },
        { value: 'routeData', text: 'Route Data' },
			  { value: 'customValue', text: 'Custom Value' },
			  // { value: 'parentValue', text: 'Parent value' },
				{ value: 'parentRenderId', text: 'Parent Modal ID'},
			  { value: 'currentForm', text: 'Current Form' },
				{ value: 'select2SearchInput', text: 'Select2 Search Input' },
			],
			options_col_method: [
			  { value: null, text: '' },
			  { value: 'and', text: 'AND' },
				{ value: 'or', text: 'OR' },
			],
		  options_selection: [
			  { value: null, text: '' },
			  { value: 'route', text: 'Data from API' },
        { value: 'external', text: 'Data from External (v3)' },
			  { value: 'settings', text: 'Data from settings' },
			],
		  options_col_type: [
			  { value: null, text: '' },
			  { value: 'text', text: 'text' },
				{ value: 'number', text: 'number' },
				{ value: 'sliderNumber', text: 'sliderNumber' },
			  { value: 'textLanguage', text: 'textLanguage' },
			  { value: 'textLanguageOptions', text: 'textLanguageOptions' },
				{ value: 'textLanguages', text: 'textLanguages' },
			  { value: 'email', text: 'email' },
			  { value: 'password', text: 'password' },
			  { value: 'select2', text: 'select2' },
				{ value: 'selectSearch', text: 'selectSearch' },
        { value: 'selectMultiSearch', text: 'selectMultiSearch' },
        { value: 'selectSearchProduct', text: 'selectSearchProduct' },
			  { value: 'dateTime', text: 'dateTime' },
        { value: 'date', text: 'date' },
			  { value: 'address', text: 'address' },
				{ value: 'addressFields', text: 'addressFields' },
			  { value: 'checkbox', text: 'checkbox' },
			  { value: 'uploadImageSingle', text: 'uploadImageSingle' },
				{ value: 'uploadImageMulti', text: 'uploadImageMulti' },
        { value: 'uploadFiles', text: 'uploadFiles' },
				{ value: 'HTMLeditor', text: 'HTMLeditor' },
				{ value: 'HTMLeditorLanguages', text: 'HTMLeditorLanguages' },
				{ value: 'configuration', text: 'configuration'},
        { value: 'openingHours', text: 'openingHours'},
				{ value: 'defaultRouteField', text: 'Default Route Field'},
        { value: 'codeViewer', text: 'codeViewer'}
			],
			options_col_numbers: [
			  { value: null, text: '' },
			  { value: '1', text: '1' },
			  { value: '2', text: '2' },
			  { value: '3', text: '3' },
			  { value: '4', text: '4' },
			  { value: '5', text: '5' },
			  { value: '6', text: '6' },
			  { value: '7', text: '7' },
			  { value: '8', text: '8' },
			  { value: '9', text: '9' },
			  { value: '10', text: '10' },
			  { value: '11', text: '11' },
			  { value: '12', text: '12' }
			],
			options_col_validation: [
			  { value: null, text: '' },
			  { value: 'email', text: 'email' },
			  { value: 'datetime', text: 'datetime' },
			  { value: 'number', text: 'number' },
			  { value: 'text', text: 'text' },
			  { value: 'json', text: 'json' }
			],
		  options_col_field: [],
		  options_col_field_filter: [[]],
             spinner: '',
             save_button: true,
             optionsEndpointData: [],
             uid: this.$route.params.uid,
		  ajaxData: {"component":{"fields":null}}
          }
      },
	methods:{
		async saveChanges () {
		  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
		  this.save_button = false
		  axios({
			method: 'put',
			url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid,
			data: {
				fields: this.$refs.Fields.value
			  }
		  }).then((response) => {
			this.ajaxData = response.data.result
			this.spinner = ''
			this.save_button = true
		  }, (error) => {
			console.log(error)
		  })
		},
		async refreshTable () {
		  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
		  this.repeaterData = null
		  this.save_button = false
		  axios({
			method: 'get',
			url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid
		  }).then((response) => {
		    this.repeaterData = JSON.parse(response.data.result.item.fields)
			this.routeFilter = [[]];

			if(response.data.result.item.fields == null){
				this.repeaterData = []
			} else {
				for (let i = 0; i < this.repeaterData.length; i++) {
					if(this.repeaterData[i].options){
						if(this.repeaterData[i].options.route_filter){
							this.routeFilter[i] = this.repeaterData[i].options.route_filter;
						} else if (this.repeaterData[i].options.filters) {
              this.routeFilter[i] = this.repeaterData[i].options.filters;
            }
					}

					if(this.repeaterData[i].disabled){
						this.field_disabled[i] = this.repeaterData[i].disabled;
					}

					if(this.repeaterData[i].required){
						this.field_required[i] = this.repeaterData[i].required;
					}
					
					if(this.repeaterData[i].hidden){
						this.field_hidden[i] = this.repeaterData[i].hidden;
					}
				}
			}

			this.ajaxData = response.data.result
			this.spinner = ''
			this.save_button = true
		  }, (error) => {
			console.log(error)
		  })
		},
		repeaterFilterUpdate(index) {
			for (let i = 0; i < this.repeaterData.length; i++) {
				if (index == i) {
					if(!this.routeFilter[i] || this.routeFilter[i] == ''){
						this.repeaterData[i].options.route_filter = []
					} else {
						this.repeaterData[i].options.route_filter = this.routeFilter[i]
					}
				}
			}
		},
		repeaterFilterGetFieldOptions(index) {
			axios({
				method: 'options',
				url: 'https://keetels-api.eliteswitch.com/api/'+this.repeaterData[index].options.link
			  }).then((response) => {
				if(response.data.result){
				this.options_col_field_filter[index] = [];
				 for (const field in response.data.result.data.fields) {
					this.options_col_field_filter[index].push({value: field, text: field})
				 }
				}

			  }, (error) => {
				console.log(error)
			  })

		},

		checkbox_disabled_set_value(index, e) {
			this.repeaterData[index].disabled = e;
		},
		checkbox_required_set_value(index, e) {
			this.repeaterData[index].required = e;
		},
		checkbox_hidden_set_value(index, e) {
			this.repeaterData[index].hidden = e;
		},
		update_checkbox(a, index) {
			for (const key in this.optionsEndpointData.fields) {
				if(key == a){
					if(this.optionsEndpointData.fields[key].includes("required")){
						this.field_required[index] = true;
						this.repeaterData[index].required = true;

						this.field_disabled[index] = false;
						this.repeaterData[index].disabled = false;
						return '';
					}
				}
			}

			this.repeaterData[index].required = false;
			this.repeaterData[index].disabled = false;
			this.field_required[index] = false;
			this.field_disabled[index] = false;
		},
		check_disabled(a, index) {
			for (const key in this.optionsEndpointData.fields) {
				if(key == a){
					if(this.optionsEndpointData.fields[key].includes("required")){

							if(!this.repeaterData[index].required){
								this.field_required[index] = true;
								this.repeaterData[index].required = true;
							}
						return true;
					}
				}
			}
			return false;
		},
		saveTrigger() {
		  this.ajaxData.item.fields = this.$refs.Fields.value
		  this.saveChanges()
		},
		positionUp: function(val){
			if( val == 0){
				return false;
			}
			const newArray = this.repeaterData;
			const new_index = (val - 1);
			const current_index = val;

			if (new_index >= newArray.length) {
				let k = new_index - newArray.length + 1;
				while (k--) {
					newArray.push(undefined);
				}
			}
			newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
		},
		positionDown: function(val){
			if( val == (this.repeaterData.length - 1)){
				return false;
			}
			const newArray = this.repeaterData;
			const new_index = (val + 1);
			const current_index = val;

			if (new_index >= newArray.length) {
				let k = new_index - newArray.length + 1;
				while (k--) {
					newArray.push(undefined);
				}
			}
			newArray.splice(new_index, 0, newArray.splice(current_index, 1)[0]);
		},
		generate: function(){
			this.repeaterData.push({type:'', field:'', label: '', required: false, disabled: false, hidden: false})
		},
		generateRouteFilter: function(index){
			if(!this.routeFilter[index] || this.routeFilter[index] == ''){
				this.routeFilter[index] = [];
			}
			this.routeFilter[index].push({type:'', field:'', label: ''})
			this.repeaterFilterUpdate(index);
		},
		remove: function(e, index){
			this.repeaterData.splice(index, 1);

		},
		removeRouteFilter: function(e, index_filter, index){
			this.routeFilter[index].splice(index_filter, 1);
			this.repeaterFilterUpdate(index);
		},
		options_continue(a){
			if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
				if(this.repeaterData[a].options['selection'] == null){
					this.repeaterData[a].options['link'] = ''
					this.repeaterData[a].options['field'] = ''
					this.positionUp(0)
				}
			}
		},
		select_option(a, b, c){
			if(!Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
				this.repeaterData[a].options = {}
				this.repeaterData[a].options[c] = b
				this.positionUp(0)
			}
		},
		change_option(a, b, c, d){
			if(Object.prototype.hasOwnProperty.call(this.repeaterData[a],'options')){
				this.repeaterData[a].options[c] = b
				this.positionUp(0)
			} else{
				this.repeaterData[a].options = {}
				this.repeaterData[a].options[c] = b
				this.positionUp(0)
			}
		},
		checkOptions(obj, check){
			if(Object.prototype.hasOwnProperty.call(obj, 'options')){
				if(Object.prototype.hasOwnProperty.call(obj.options, check)){
					return obj.options[check];
				}
			}
		}
	},
	watch: {
		ajaxData: function (val) {
			this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			this.save_button = false
			axios({
				method: 'options',
				url: 'https://keetels-api.eliteswitch.com/api/'+val.item.endpoint
			  }).then((response) => {
				if(response.data.result){
				 for (const field in response.data.result.data.fields) {
					this.options_col_field.push({value: field, text: field})
				 }
				}
				this.optionsEndpointData = response.data.result.data;

				 this.spinner = ''
				this.save_button = true

			  }, (error) => {
				console.log(error)
			  })

		},
	  },
	created: function(){
		this.repeaterData = [];
	},
	mounted () {
		this.refreshTable()
	  },
   }
</script>